
  import { FloorViewMode } from '~/store/building/-constants'

  export default {
    name: 'MoleculeZoomSlider',
    data() {
      return {
        viewModes: FloorViewMode,
        zoomSettings: {
          zoomValue: 0,
          minValue: 0,
          maxValue: 100
        }
      }
    },
    computed: {
      activeProject() {
        return this.$store.state.project.activeProject
      },
      generalConfig() {
        return this.$store.state.base.meta.generalConfig
      },
      buildingStore() {
        return this.$store.state.building
      },
      viewMode() {
        return this.buildingStore.floorViewMode
      },
      engine3d() {
        return this.$engine3d || window.engine3d
      },
      sliderZoomValue() {
        return this.generalConfig.modelDisplaySettings[this.activeProject].floor?.sliderZoomValue || 0
      },
    },
    mounted() {
        this.$nextTick(() => {
            this.setSliderValue(this.sliderZoomValue);
        });
    },
    methods: {
        handleDrag(e) {
            if (e.target.id !== 'dragger') {
                return
            }
            const parentRect = e.target.parentElement.getBoundingClientRect()
            const zoomRect = e.target.getBoundingClientRect()
            const absoluteYPositionWithinContainer = e.pageY - window.pageYOffset
            const cursorVal =
                e.pageY !== 0
                ? parentRect.bottom - absoluteYPositionWithinContainer - zoomRect.height / 2
                : 0
            if (
                cursorVal > 0 &&
                cursorVal <= this.zoomSettings.maxValue - zoomRect.height / 2 &&
                cursorVal >= this.zoomSettings.minValue
            ) {
                this.zoomSettings.zoomValue = cursorVal
                this.handleZoom();
            }
        },
        handleZoom(zoomCamera = true) {
            if (!this.$refs.dragger) return;
            const percentVal = (this.zoomSettings.zoomValue * 100) / this.zoomSettings.maxValue
            if(typeof this.$refs.dragger !== 'undefined') {
                this.$refs.dragger.style.bottom = percentVal > 5 ? `${percentVal}%` : `${0}%`
            }
            const manager = this.engine3d.getClientManager()
            if (manager && zoomCamera) {
                manager.setZoomValue(this.zoomSettings.maxValue - this.zoomSettings.zoomValue)
            }
        },
        setSliderValue(value) {
            this.zoomSettings.zoomValue = value;
            this.handleZoom(false);
        },
        handleDragStart(e) {
            if (e.target.id !== 'dragger') {
                return
            }
            e.dataTransfer.setDragImage(e.target, window.outerWidth + 100, window.outerHeight + 100)
        },
        increaseZoomValue() {
            if (this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 5) {
                this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 10
                ? (this.zoomSettings.zoomValue += 5)
                : (this.zoomSettings.zoomValue = this.zoomSettings.maxValue - 5)
                this.handleZoom()
            }
        },
        decreaseZoomValue() {
            if (this.zoomSettings.zoomValue > this.zoomSettings.minValue) {
                this.zoomSettings.zoomValue > 5
                ? (this.zoomSettings.zoomValue -= 5)
                : (this.zoomSettings.zoomValue = 0)
                this.handleZoom()
            }
        }
    }
  }
  