
export default {
  name: 'MoleculePinExpand',
  props: {
    pinData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
  }
}
