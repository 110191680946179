
export default {
  name: 'MoleculeFitoutSelector',
  props: {
    fitoutData: {
      required: true,
      type: Array,
      default: () => []
    },
    changeFitout: {
      required: true,
      type: Function,
      default: () => {}
    },
    disclaimer: {
      required: false,
      type: [String, Object],
      default: ''
    }
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    fitoutsOpts() {
      let newfitoutData = []
      let opts = [
        {
          text: this.$t('selectFitout'),
          value: '',
          disabled: true
        },
        { text: this.$t('none'), value: 'none' }
      ]
      if (this.fitoutData && this.fitoutData.length > 0) {
        newfitoutData = this.fitoutData.map((obj) => ({
          text: obj.text,
          value: obj.value
        }))
        opts = [...opts, ...newfitoutData]
      }
      return opts
    }
  }
}
